<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 5 anos.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

   
    <!-- Diálogo de progresso -->
    <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :opcoes2="opcoes2"  :modos="modos" :tipos="tipos" :calendar="'decadas'" :produtos="tipos_funerarios" 
      @filtrar="filterData" :title_opcao="'Status do Pagamento'"   :title_opcao2="'Situação'"
        ></filtro-component>
    </v-col>
  </v-container>


  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
 
    <v-row>
    
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-cash-refund"
        title="Lista dos Negativados"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="negativados_header"
              :items="negativados"
              :items-per-page="50" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      

                        <template v-slot:item="row">
                          
                          <tr :class="{'gray-row': row.index % 2 !== 0}">

                            <td style="width: 22%;font-size:14px;">
                               {{row.item.chNomeCompleto}} <br/>
                               CPF: {{row.item.cpf}} 
                            
                            </td>
                            <td style="width: 10%;font-size:14px;">{{row.item.chReferencia}} </td>

                            <td style="width:10%;font-size:14px;">
                              <v-chip 
                              :color="getEmpresa(row.item.chNomeFantasia)"
                              text-color="white">
                                  {{row.item.chNomeFantasia}}                    
                                  
                              </v-chip>  
                            </td>
                            <td style="width: 10%;font-size:14px;">{{row.item.nmIdDocumento}} </td>


                            <td style="width: 10%;font-size:14px;"> 
                           
                              {{
                                row.item.mnValorTotal ? parseFloat(row.item.mnValorTotal).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                }) : 'R$ 0,00'
                              }}     
                            </td>

                            
                            <td style="width: 10%;font-size:14px;">{{row.item.statusReneg}} </td>
                            <td style="width: 6%;font-size:14px;">{{row.item.renegId}} </td>

                            <td style="width: 8%;font-size:14px;">
                              {{ verificarRenegociacao(row.item.valorReneg, row.item.totalPagoReneg, row.item.mnTotalRecebido) }} 
                           </td>

                            <td style="width: 6%;font-size:14px;">

                              <v-chip outlined :color="verificarRenegociacaoCor(row.item.valorReneg, row.item.totalPagoReneg, row.item.mnTotalRecebido)" >
                                {{ verificarRenegociacaoObs(row.item.valorReneg, row.item.totalPagoReneg, row.item.mnTotalRecebido) }} 
                              </v-chip>

                            </td>


                            
                          </tr>
                      </template>
              </v-data-table>


    <br/>

  

          </base-material-card>
  
          <br/>
  
  <div class="d-flex justify-end" style="color:green; font-size: 48px; margin-right: 12px;">
    <span @click="download_xls_negativado" style="cursor: pointer;"><i class="fas fa-file-excel"></i></span>
  </div>

    </v-col>

   
  

  </v-row>



  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Cobranca from '../../services/cobranca'
import { ContentLoader } from 'vue-content-loader'
import FiltroComponent from "./components/FiltroDuo.vue"; // Certifique-se de que o caminho esteja correto


  export default {
    name: 'Cobranca',
  components: {
    apexcharts: VueApexCharts,
    FiltroComponent,
    ContentLoader,

  },
  
  created() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = this.getFirstDayOf60YearsAgo(),
     // `${"01"}/${month}/${year}`;
      this.selectedEndDate =  this.formatDateBR(new Date())
     
    },
    
  mounted() {

    this.dashboard();
   
    
  },

 
    data () {
      return {
       
        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          empresa: 'Todas',
          search: "",
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectedOpcao2: "NEGATIVADO",

          selectMode: "Todos",
          produto: 'Todos',
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todas", "PAGO", "PENDENTE"],
          opcoes2: ["NEGATIVADO",  "REABILITADO"],
          modos: ["Cadastro"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],

          bool_01:true,
          bool_02:true,
          bool_03:true,
          bool_04:true,
          showProgress: false,
          currentGroup: null,
          shownDataAcordo: new Set(),


          tipos_funerarios: [
                "Todos",
              ],
        


        negativados_header: [
          { text: 'Cliente', value: 'usuario' },
          { text: 'Contrato', value: 'contrato' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Documento', value: 'valorTotal' },
          { text: 'Valor Parcela', value: 'valorRecebido' },
          { text: 'Situação', value: 'percentualRecebido' },
          { text: 'Acordo', value: 'percentualRecebido' },
          { text: 'Status do Pagamento', value: 'percentualRecebido' },


        ],

        negativados: [],
        lista_composicao: [],

         lista_composicao_header: [
          { text: 'Acordo', value: 'id' },
          { text: 'Data', value: 'data_acordo' },
                    { text: 'Total Original', value: 'soma_parcela_original' },
          { text: 'Total Renegociado', value: 'total_reneg' },
          { text: 'Parcela Original', value: 'vencimento_original' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Parcelas', value: 'parcelamento' },
          { text: '', value: '' },
          { text: '', value: '' },


        ],

          total_cobranca_reneg_cancelado: 0,
          total_cobranca_reneg_volume: 0,
          total_cobranca_reneg_ativa: 0,

          total_cobranca_reneg_cancelado_r: 0,
          total_cobranca_reneg_volume_r: 0,
          total_cobranca_reneg_ativa_r: 0

      }
  },

    methods: {


      verificarRenegociacao(valorReneg, totalPagoReneg, pagoParcela) {

        if (pagoParcela !== null && pagoParcela !== '' && pagoParcela >= 0) {
          return 'PAGO';
        }

        if (totalPagoReneg >= valorReneg && totalPagoReneg > 0) {
          return 'PAGO';
        } 

        return 'PENDENTE'
      },

      verificarRenegociacaoObs(valorReneg, totalPagoReneg, pagoParcela) {

          if (pagoParcela !== null && pagoParcela !== '' && pagoParcela >= 0) {
            return 'PARCELA PAGA';
          }

          if (totalPagoReneg >= valorReneg && totalPagoReneg > 0) {
            return 'RENEG PAGA';
          } 

          return 'PENDENTE'
          },

          verificarRenegociacaoCor(valorReneg, totalPagoReneg, pagoParcela) {

            if (pagoParcela !== null && pagoParcela !== '' && pagoParcela >= 0) {
              return 'red';
            }

            if (totalPagoReneg >= valorReneg && totalPagoReneg > 0) {
              return 'orange';
            } 

            return 'green'
            },

      getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

        formatarData(data) {
          if (!data) return ''; // Verificar se a data não está vazia ou indefinida

          // Dividir a data em ano, mês e dia
          const partes = data.split('-');
          if (partes.length !== 3) return data; // Se a data não estiver no formato esperado, retornar a data original

          // Montar a data no formato desejado (dd/mm/aaaa)
          const dia = partes[2];
          const mes = partes[1];
          const ano = partes[0];
          return `${dia}/${mes}/${ano}`;
        },

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },
            formatarObs(obs) {
              // Substituir \n por <br> para exibir corretamente no HTML
              return obs.replace(/\n/g, '<br>');
            },

       getFirstDayOf60YearsAgo() {
            const dataAtual = new Date();
            const anoAtual = dataAtual.getFullYear();
            const ano60Atras = anoAtual - 60;
            const primeiroDiaDoAno60Atras = new Date(ano60Atras, 0, 1); // Janeiro é representado como 0
            return this.formatDateBR(primeiroDiaDoAno60Atras);
        },

       filterData(valores) {

         this.isLoading = true;
         let nova_consulta = false;

          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate
          || this.selectedCompany !== valores.selectedCompany
          || this.selectedType !== valores.selectedType) {
            nova_consulta = true;
          }

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedOpcao2 = valores.selectedOpcao2;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.produto = "Todos";
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;

        if(nova_consulta){
          this.total_cobranca_reneg_volume = 0,
          this.total_cobranca_reneg_cancelado = 0,
          this.dashboard();

        }else{
            //console.log('Valor'," Recerregar Dados");
        }

      },


      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

     
      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },

      
      download_xls_negativado() {
              
             this.showProgress = true;
             const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
             const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

             
             Cobranca.lista_negativado_xls(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.selectedOpcao2)
             .then(response => {
                      if (response.status === 200) {

                        const fileName = `negativado_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()

                        }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });


                     
    },



    
    shouldShowDataAcordo(id) {
      if (!this.shownDataAcordo.has(id)) {
        this.shownDataAcordo.add(id);
        return true;
      }
      return false;
    },

    getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

    getStatus (status) {
            switch (status) {
            case 'Ativo': return 'blue lighten-1'
            case 'Cancelado' : return 'red lighten-1'
             default: 'success'
            }
      },

     getPago(valor_titulo, valor_pago) {
          if (valor_pago >= valor_titulo) {
              return 'green lighten-1';
          } else {
              return 'red lighten-1';
          }
      },

      getPagoDescricao(valor_titulo, valor_pago) {
          if (valor_pago >= valor_titulo) {
              return 'Quitada';
          } else {
              return 'Em Aberto';
          }
      },


      dashboard() {
 
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

              /*
              if (diferencaEmDias > 1827) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
              }
              */

              Cobranca.lista_negativados(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, this.produto, this.selectedOpcao2).then(response => {
                          if(response.status === 200){
                            this.negativados = response.data;
                            this.aplicarFiltro();                           
                          } 

                        }).catch(e => {    
                        }).finally(() => {
                         
               });

      },

      aplicarFiltro() {

        if (this.selectedOpcao === "Todas") {
          return;
        }
     

        this.negativados = this.negativados.filter(row => {
          const statuss = this.verificarRenegociacao(row.valorReneg, row.totalPagoReneg, row.mnTotalRecebido);
          return statuss === this.selectedOpcao;
          
        });

      }

    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  .vuetify__expand-icon {
  display: none !important; /* Oculta os ícones de expandir */
}
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-data-table-header-mobile .v-data-table-header-group {
  display: none !import; /* Oculta os botões de expansão de grupo */
}
  
  </style>
