<template>
  <v-container fluid style="margin:0px">
    <v-card elevation="0" style="margin-top: 0px !important; margin-bottom: -30px !important; overflow: hidden;background-color:transparent" class="px-5 py-1">
      <v-row class="custom-gray-background fill-width d-flex" v-if="mostrarFiltro">
        <v-col cols="12" sm="6" md="4" v-if="modos.length > 0" >
          <span v-if="modos.length > 0">Filtrar por</span>
          <v-select v-model="selectMode" :items="modos" label="Filtrar" dense solo-inverted></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <span>Data Inicial</span>
          <v-menu v-model="isStartDatePickerOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template #activator="{ on }">
              <v-text-field v-model="selectedStartDate" label="Data Inicial" dense solo-inverted readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker no-title v-model="localSelectedStartDateISO" @input="handleStartDateSelection"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <span>Data Final</span>
          <v-menu v-model="isEndDatePickerOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template #activator="{ on }">
              <v-text-field v-model="selectedEndDate" label="Data Final" dense solo-inverted readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker no-title v-model="localSelectedEndDateISO" @input="handleEndDateSelection"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <span>Empresa</span>
          <v-select v-model="selectedCompany" multiple  @input="handleEmpresaSelection" :items="filteredEmpresas"  label="Empresa" required dense solo-inverted></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <span>Tipo</span>

          <v-select v-model="selectedType" :items="tipos" label="Tipo" dense solo-inverted></v-select>

        </v-col>
       
        <v-col cols="12" sm="6" md="4" v-if="opcoes.length > 0" >
          <span v-if="opcoes.length > 0">{{ title_opcao }}</span>
          <v-select v-model="selectedOpcao" :items="opcoes" label="Opção" dense solo-inverted></v-select>
        </v-col>
      
        <v-col cols="12" sm="6" md="4" v-if="opcoes.length > 0" >
          <span v-if="opcoes2.length > 0">{{ title_opcao2 }}</span>
          <v-select v-model="selectedOpcao2" :items="opcoes2" label="Opção" dense solo-inverted></v-select>
        </v-col>


      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">

        <v-btn @click="alternarVisibilidade" color="#4682B4" small class="mr-2" style="font-size:14px !important;font-weight:bold">
          {{ mostrarFiltro ? 'Ocultar Filtro' : 'Mostrar Filtro' }}
          <v-icon style="margin-left:8px" dark>{{ mostrarFiltro ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
        </v-btn>


       <v-btn @click="limparFiltro" v-if="mostrarFiltro" color="#4682B4" small outlined class="mr-2" style="font-size:14px !important;font-weight:bold">
          Limpar
        </v-btn>
        <v-btn @click="filtrar" v-if="mostrarFiltro" color="#4682B4" small style="font-size:14px !important;font-weight:bold">
          Filtrar <v-icon style="margin-left:8px" dark>mdi-filter</v-icon>
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-container>
</template>


  
  
  <script>

  export default {

    props: {
      opcoes: Array,
      opcoes2: Array,
      modos: Array,
      tipos: Array,
      produtos: Array,
      title_opcao: String,
      title_opcao2: String,

      calendar: String
    },

    created() {
     

        if (this.modos.length > 0) {
          this.selectMode = this.modos[0];
        }
        if (this.tipos.length > 0) {
          this.selectedType = this.tipos[0];
        }
        if (this.produtos.length > 0) {
          this.selectedProduto = this.produtos[0];
        }

        this.selectedEndDate = this.formatDateBR(new Date());
        
            if (this.calendar === null || this.calendar === "mes") {
              // Se calendar for null ou "mes", obtenha o primeiro dia do mês
              this.selectedStartDate = this.getFirstDayOfMonth();
            }else if (this.calendar === "decadas") {
              // Se calendar for "ano", obtenha o primeiro dia do ano
              this.selectedStartDate = this.getFirstDayOf60YearsAgo();
            }else if (this.calendar === "ano") {
              // Se calendar for "ano", obtenha o primeiro dia do ano
              this.selectedStartDate = this.getFirstDayOfYear();
            } else if (this.calendar === "quinzena") {
           
              this.selectedStartDate = this.getFirstDayOfMonth();
              this.selectedEndDate = this.getEndDateWithOffset(15);

            } else if (this.calendar === "dia") {
           
                this.selectedStartDate = this.getFirstDayOfMonth();
                this.selectedEndDate = this.getEndDateWithOffset(0);

              }  else {
              // Se calendar for qualquer outro valor, obtenha o primeiro dia do mês por padrão
              this.selectedStartDate = this.getFirstDayOfMonth();
            };

    
      },


    data() {
    return {
     
      mostrarFiltro: true,

      localSelectedEndDateISO: "",
      localSelectedStartDateISO: "",
      selectedStartDateISO: "",
      selectedStartDate: "",
      selectedEndDate: "",
      selectedEndDateISO: "",
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,


          empresa: 'Todas',
          selectedCompany: ["Todas"],
          selectedType:  "",
          selectedOpcao: "Todas",
          selectedOpcao2: "NEGATIVADO",
          selectMode:  "",
          selectedProduto: ["Todos"],
          user: "",

          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DAS FLORES", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          //userRoles: ["ROLE_PARQUE", "ROLE_PREVIDA"], // Substitua isso pelos papéis reais do usuário
          //userRoles: [],
          //opcoes: ["Pago", "Em Aberto", "Todas"],
          //modos: ["Vencimento", "Pagamento"],
    };
    },


    computed: {

    

    filteredEmpresas() {
      // Filtra as empresas com base nos papéis do usuário
      return this.empresas.filter(empresa => this.hasPermission(empresa));
    },

    produtosFiltrados() {
        // Filtrar os produtos com base nos tipos permitidos
        return this.produtos.filter(produto => this.tiposPermitidos.includes(produto));
    },
    tiposPermitidos() {
        let tiposPermitidos = ["Todos"];

        if (this.selectedType === "Jazigo") {
            tiposPermitidos = ["Todos", "CONCESSÃO DE COLUMBÁRIO", "CONCESSÃO DE JAZIGOS", "CONCESSÃO DE OSSÁRIO", "VENDA DE COLUMBÁRIO", "VENDA DE JAZIGOS - IMEDIATO", "VENDA DE JAZIGOS - PREVENTIVO", "VENDA DE JAZIGOS - TRANSITÓRIO"];
        } else if (this.selectedType === "Planos") {
            tiposPermitidos = ["Todos", "COMPLETO", "FAMILIA", "INTEGRAL", "P23", "PARQUE TOTAL", "PF - AMPARO", "PROTEÇÃO", "PV - AMIGO", "PV - BRONZE", "PV - CREMAÇÃO COM CERIMÔNIA", "PV - DIAMANTE", "PV - EMPRESARIAL", "PV - EMPRESARIAL - EMPRESA", "PV - ESPECIAL", "PV - ESMERALDA", "PV - ESSENCIAL", "PV - IDEAL", "PV - MAIOR", "PV - MAIS", "PV - MASTER GOLD", "PV - MELHOR", "PV - PLANO PJ", "PV - PLUS", "PV - POLICIA MILITAR", "PV - PRATA", "PV - PREVIDA", "PV - PREVIPARQ DO AGRESTE", "PV - RUBI", "PV - SÃO MATHEUS"];
        } else if (this.selectedType === "Cremação") {
            tiposPermitidos = [ "Todos",  "CREMAÇÃO", "CREMAÇÃO COM CERIMÔNIA", "CREMAÇÃO SEM CERIMÔNIA", "URNAS CINERARIAS"];
        } else if (this.selectedType === "Todos" ||  this.selectedType ===  "Funerária" || this.selectedType ===  "Serviços Administrativos" || this.selectedType ===  "Serviços Cemiteriais") {
            tiposPermitidos = [ "Todos"];
        }

        return tiposPermitidos;
    }

  },
 

    methods:{

      
        alternarVisibilidade() {
        this.mostrarFiltro = !this.mostrarFiltro;
      },
      
        filtrar() {

          this.$emit("filtrar", {
            selectedOpcao: this.selectedOpcao,
            selectedOpcao2: this.selectedOpcao2,
            selectedCompany: this.selectedCompany,
            selectMode: this.selectMode,
            selectedStartDate: this.selectedStartDate,
            selectedEndDate: this.selectedEndDate,
            selectedType: this.selectedType,
            selectedProduto: this.selectedProduto,

            // Adicione outras propriedades conforme necessário
          });

        },

        handleEmpresaSelection() {
          if (this.selectedCompany.includes("Todas")) {
            // Se "Todas" estiver selecionada, desabilita as outras opções
            this.selectedCompany = ["Todas"];
          }
        },

        limparFiltro(){

            this.empresa =  'Todas',
            this.selectedCompany = ["Todas"],
            this.selectedType = "Todos",
            this.selectedOpcao = "Todas";
            this.selectedOpcao2 = "Todas";

            this.selectedProduto = "Todos";

            if (this.modos.length > 0) {
              this.selectMode = this.modos[0];
            }

            if (this.tipos.length > 0) {
              this.selectedType = this.tipos[0];
            }

            if (this.calendar === null || this.calendar === "mes") {
              // Se calendar for null ou "mes", obtenha o primeiro dia do mês
              this.selectedStartDate = this.getFirstDayOfMonth();
            }else if (this.calendar === "decadas") {
              // Se calendar for "ano", obtenha o primeiro dia do ano
              this.selectedStartDate = this.getFirstDayOf60YearsAgo();
            } else if (this.calendar === "ano") {
              // Se calendar for "ano", obtenha o primeiro dia do ano
              this.selectedStartDate = this.getFirstDayOfYear();
            } else if (this.calendar === "quinzena") {
           
              this.selectedStartDate = this.getFirstDayOfMonth();
              this.selectedEndDate = this.getEndDateWithOffset(15);

            }else if (this.calendar === "dia") {
              
              this.selectedStartDate = this.getFirstDayOfMonth();
              this.selectedEndDate = this.getEndDateWithOffset(0);

            }   else {
              // Se calendar for qualquer outro valor, obtenha o primeiro dia do mês por padrão
              this.selectedStartDate = this.getFirstDayOfMonth();
            };


            this.selectedEndDate =  this.formatDateBR(new Date())

        },


        handleStartDateSelection() {
          this.selectedStartDate= this.convertToBR(this.localSelectedStartDateISO);
          this.isStartDatePickerOpen = false; 
        },

        handleEndDateSelection() {
          this.selectedEndDate= this.convertToBR(this.localSelectedEndDateISO);
          this.isEndDatePickerOpen = false; 
        },


        convertToISO(dateString) {
                // Converte a data do formato "20/10/2023" para "2023-10-20"
                const [day, month, year] = dateString.split("/");
                return `${year}-${month}-${day}`;
              },

       convertToBR(dateString) {
                // Converte a data do formato ISO "2023-10-20" para "20/10/2023"
                const [year, month, day] = dateString.split("-");
                return `${day}/${month}/${year}`;
       },

       getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

      

        getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

        hasPermission(empresa) {

          this.user = JSON.parse(sessionStorage.getItem('user'));

          // Verifica se o usuário tem permissão para ver a empresa
          if (this.user.roles.includes("ROLE_ADMIN")) {
            // Se o usuário tiver a role ROLE_ADMIN, ele pode ver todas as empresas
            return true;
          } else if (empresa === "Todas") {
            // A empresa "Todas" está sempre disponível para todos os usuários
            return true;
          } else {

            
            const requiredRoles_ = empresa.split(',').map(role => role.trim().toUpperCase().replace(/\s/g, '_'));
            const requiredRoleString = 'ROLE_' + requiredRoles_;
          
            const hasRequiredRole = this.user.roles.includes(requiredRoleString);
            return hasRequiredRole;
          }
        },

        getEndDateWithOffset(offsetDays) {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1); // Janeiro é representado como 0

          // Crie a variável endDate aqui
          const endDate = new Date(primeiroDiaDoAno);

          // Adicione o número de dias ao endDate
          endDate.setDate(endDate.getDate() + offsetDays);

          return this.formatDateBR(endDate);
        },

        getFirstDayOf60YearsAgo() {
            const dataAtual = new Date();
            const anoAtual = dataAtual.getFullYear();
            const ano60Atras = anoAtual - 60;
            const primeiroDiaDoAno60Atras = new Date(60, 0, 1); // Janeiro é representado como 0
            return this.formatDateBR(primeiroDiaDoAno60Atras);
      },

            formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },


            formatDateUS(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${month}/${day}/${year}`;
              },

              convertToISO(dateString) {
                // Converte a data do formato "20/10/2023" para "2023-10-20"
                const [day, month, year] = dateString.split("/");
                return `${year}-${month}-${day}`;
              },

              convertToBR(dateString) {
                // Converte a data do formato ISO "2023-10-20" para "20/10/2023"
                const [year, month, day] = dateString.split("-");
                return `${day}/${month}/${year}`;
              },

    },



  };
  </script>
  <style>
  /* Certifique-se de que o arquivo CSS com a classe .custom-col esteja importado aqui */
  .custom-col-13 {
    flex-basis: 13%; /* 15% é equivalente a 1.5 colunas em uma grade de 10 colunas */
    max-width: 13%;
  }
  .custom-col-15 {
    flex-basis: 15%; /* 15% é equivalente a 1.5 colunas em uma grade de 10 colunas */
    max-width: 15%;
  }
  </style>
  